import styled from "styled-components";
import { up, down } from "styled-breakpoints";
import Typography from "src/components/atoms/Typography";

export const TopBar = styled.div`
  padding: 1em 0;
  background: #104e80;

  ${down("sm")} {
    padding: 1em;
  }
`;

export const TopBarText = styled(Typography).attrs({ variant: "h6" })`
  p {
    font-family: ${({ theme }) => theme.typography.fontFamilies.secondary};
    text-align: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.5rem;
    padding: 0;
    margin: 0;
    color: ${({ theme }) => theme.palette.common.white};

    ${up("lg")} {
      br {
        display: none;
      }
    }

    ${down("md")} {
      text-align: center;
    }

    a {
      color: inherit;
    }
  }
`;

export const TopBarSubText = styled.div`
  a {
    font-family: ${({ theme }) => theme.typography.fontFamilies.secondary};
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.6em;
    text-decoration: none;
  }
`;
