import React from "react";
import { IMainHeaderContentFields } from "src/types/generated/contentful";

import { Box, Flex } from "@chakra-ui/react";
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";

import GetYourQuoteButton from "src/components/molecules/GetYourQuoteButton";
import { TopBarSubText, TopBarText } from "./styles";

export function MainHeaderContent(content: IMainHeaderContentFields) {
  // console.log("MainHeaderContent", content);
  const { title, subTitle } = content;

  return (
    <Box bg="#235d7e" p={4}>
      {/** TopBarText is an h6 wrapping all of this?!?! why? */}
      <TopBarText data-testid="title">
        <ReactMarkdown remarkPlugins={[remarkBreaks]}>
          {title ? title.replace(/\\n/g, "\n").trim() : ""}
        </ReactMarkdown>
        <TopBarSubText data-testid="sub-title">
          <ReactMarkdown remarkPlugins={[remarkBreaks]}>
            {subTitle ? subTitle.replace(/\\n/g, "\n").trim() : ""}
          </ReactMarkdown>
        </TopBarSubText>

        <Flex
          display={["flex", "flex", "none"]}
          alignItems="center"
          justifyContent="center"
          p="4"
        >
          <GetYourQuoteButton variant="brand-solid-light" />
        </Flex>
      </TopBarText>
    </Box>
  );
}

export default React.memo(MainHeaderContent);
